function extractValues(values) {
  let headers = values.shift();
  //shift first 3 from headers to get the names
  headers.shift();
  headers.shift();
  headers.shift();

  let names = headers;

  //meta data, how many cores completed
  values.splice(-16,16);
  // some header in between
  values.splice(221,1)
  return { names: names, counts: values}
}

function compareWcp(a, b) {
  if (a.wcp > b.wcp) return -1
  if (b.wcp > a.wcp) return 1

  return 0
}

function compareLevel(a, b) {
  if (a.core === b.core) {
    if (a.level > b.level) return 1
    if (b.level > a.level) return -1
  }
  return compareWcp(a, b)
}

function strToFloat(floatString) {
  return parseFloat(floatString.replace(/,/g, ''))
}

function parseCore(core) {
  return core.replace(/\s\(.*\)/g, '')
}

function  extractCoreValues(values) {
  values.splice(0, 3);
  return values.map(x => {
    let core = x[2]
    let result = [
      {core: parseCore(core),  level: 1, wcp: strToFloat(x[5])},
      {core: parseCore(core),  level: 2, wcp: strToFloat(x[6])},
      {core: parseCore(core),  level: 3, wcp: strToFloat(x[7])},
      {core: parseCore(core),  level: 4, wcp: strToFloat(x[8])},
      {core: parseCore(core),  level: 5, wcp: strToFloat(x[9])},
      {core: parseCore(core),  level: 6, wcp: strToFloat(x[10])},
      {core: parseCore(core),  level: 7, wcp: strToFloat(x[11])},
      {core: parseCore(core),  level: 8, wcp: strToFloat(x[12])},
      {core: parseCore(core),  level: 9, wcp: strToFloat(x[13])},
      {core: parseCore(core),  level: 10, wcp: strToFloat(x[14])}
    ]
    return result.flat()
  }).flat().sort(compareLevel)
}

async function loadCoreValues() {
  let response = await window.gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: '1i2UV4V1ppfnNnrW0S9-FHQynI7oOQbeFuLSQO5fJlHY',
    range: 'Weighted Core '
  })
  let result = response.result;
  return extractCoreValues(result.values)
}

async function loadMaxSheet() {
  let response = await window.gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: '1SeoWHvmR9bZ2QFQME7S51lNG65ySxAL03-UO9HxC3QU',
    range: 'Max\'s Tool!A:AZ'
  })
  let result = response.result;
  return extractValues(result.values)
}

const loadSheetData = () => {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  document.body.appendChild(script);

  return new Promise(function(resolve, reject) {
    script.onload = () => {
      window.gapi.load('client', async() => {
        await window.gapi.client.init({
          'apiKey': 'AIzaSyAWVjC5Af1TNBUjXzLu73OQjclCFJRlTj8',
          'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        })
        resolve({agema: await loadMaxSheet(), cores: await loadCoreValues() });
      }, (err) => { reject(err) })
    }
  });
}

export { loadSheetData };

