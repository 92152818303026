import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './App.css';
import logo from './agema.png'; // Tell Webpack this JS file uses this image
import * as Style from './style'

import { Route, Link, Redirect, withRouter } from "react-router-dom";

import CoreCounts, { personalCounts } from "./components/CoreCounts";

import { loadSheetData } from "./context/SheetLoader";

function App(props) {
  const [sheetData, setSheetData] = useState([]);
  const [ready, setReady] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  function memberOptions() {
    return sheetData.agema.names.map((member) => {
      return { 'value': member, 'label': member}
    })
  }

  const handleChange = selectedOption => {
    setSelectedMember(selectedOption.value);
    props.history.push('/personalsheet/' + selectedOption.value)
  }

  useEffect(() => {
    async function fetchData() {
      let result = await loadSheetData();
      setSheetData(result);
      setReady(true)
    };
    fetchData();
  }, []);


  function Header() {
    return (
      <div>
        <header className="App-header">
          <img className="App-logo" src={logo} alt="Logo" />
        </header>
        <div>
          <ul className="tab">
            <TabLink label="Agema" to="/" activeOnlyWhenExact={true}/>
            <TabLink label="Cores" to="/personalsheet"/>
            <TabLink label="Info" to="/info"/>
          </ul>
        </div>
      </div>
    );
  }

  function TabLink({ label, to, activeOnlyWhenExact }) {
    return (
        <Route
            path={to}
            exact={activeOnlyWhenExact}
            children={({ match }) => (
                <li className={match ? "active" : ""}>
                  <Link to={to} >{label}</Link>
                </li>
            )}
        />
    );
  }

  function SelectMember() {
    return (
        <div className={"content"}>
          <h2>Select a member</h2>
          <Select
              styles={Style.customStyles}
              className="App-Member-Select"
              defaultValue={ selectedMember ? { 'value': selectedMember, 'label': selectedMember} : undefined }
              options={memberOptions()}
              onChange={handleChange}
          />
        </div>
    );
  }

  function SelectOrRedirectMember() {
    if ((selectedMember)) {
      return <Redirect to={"/personalsheet/" + selectedMember}/>
    }
    return <SelectMember/>;
  }

  function Agema() {
    return (
          <h2>Agema</h2>
    );
  }

  function Info() {
    return (
      <h2>Info</h2>
    );
  }

  function PersonalSheet({ match }) {
    let member = match.params.membername;
    if (member && !selectedMember) {
      setSelectedMember(member)
    }
    return(
          <CoreCounts counts={personalCounts(member, sheetData)} member={member}/>
    );
  }

  function content() {
    return (
          <div className="App">
            <Header/>
            <div className={"maincontent"}>
                <div className={"content"}>
                    <Route exact path="/" component={Agema} />
                    <Route exact path="/changemember" component={SelectMember} />
                    <Route exact path="/personalsheet" component={SelectOrRedirectMember} />
                    <Route path="/personalsheet/:membername" component={PersonalSheet} />
                    <Route path="/info" component={Info} />
                </div>
            </div>
          </div>
    );
  }

  return (
    ready ? content() : "loading"
  )
}

export default withRouter(App);
