export const customStyles = {
    container: (provided) => ({
        ...provided,
        display: 'inline-block',
        minHeight: '1px',
        textAlign: 'left',
        border: 'none',
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: '5px',
        minHeight: '1px',
        height: '30px',
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
        margin: '0',
        color: '#757575',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '12px',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '30px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    singleValue: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingBottom: '2px',
    }),
};

