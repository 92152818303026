import React, { Component } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import 'react-table/react-table.css'

class CoreCounts extends Component {
  render() {
    let counts = this.props.counts;
    let member = this.props.member;

    const columns = [{
      Header: 'Stone',
      accessor: 'stone'
    },
      {
        Header: 'Area',
        accessor: 'area'
      },
      {
        Header: 'Core',
        accessor: 'core'
      },
      {
        Header: 'Level',
        accessor: 'level',
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "lt10") {
            return parseInt(row[filter.id]) < 10;
          }
          return String(row[filter.id]) === '10';
        },
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "lt10"}
          >
            <option value="lt10">&lt;10</option>
            <option value="all">Show All</option>
            <option value="finished">Finished only</option>
          </select>
      },
      {
        Header: 'Weighted CP/core for next level',
        accessor: 'wcp',
        filterMethod: (filter, row) => {
          if (filter.value === "nuod") {
            return row[filter.id] !== "unknown" &&
                row[filter.id] !== "done"
          }
          if (filter.value === "done") {
            return row[filter.id] === "done"
          }
          if (filter.value === "unknown") {
            return row[filter.id] === "unknown"
          }
          return true
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "lt10"}
            >
              <option value="all">Show all</option>
              <option value="done">Done</option>
              <option value="unknown">Unknown</option>
              <option value="nuod">Not unknown or done</option>

            </select>
      },
    ];

    return (
        <div>
          <h2>{member}
              <Link to="/changemember">
               &nbsp;  &#9998; Choose another warrior
              </Link>
          </h2>



          <ReactTable
            filterable
            className="-striped -highlight"
            defaultFiltered={[
              {
                id: 'level',
                value: 'lt10'
              },
              {
                id: 'wcp',
                value: 'nuod'
              }
            ]}
            sorted={[{
              id: 'wcp',
              desc: true
            }]}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).match(new RegExp(filter.value, 'gi'))}
            showPagination={false}
            defaultPageSize={counts.length}
            data={counts}
            pageSize={counts.length}
            minRows={1}
            columns={columns}/>
        </div>
    )
  }
}

function wcp(name, level, cores) {
  if (!level) return undefined
  if (level > 10) return "done";
  let core = cores.find(c => c.core === name && c.level === level);
  return core ? core.wcp : "unknown";
}

export function personalCounts(member, sheetData) {
  if (!member) return [];

  let members = sheetData.agema.names
  let counts = sheetData.agema.counts
  let cores = sheetData.cores

  let index = members.indexOf(member)+3

  return counts.map((count)=>{
    let level = parseInt(count[index])
    let nextLevel = isNaN(level) ? undefined : level + 1
    return {
      stone: count[0],
      area: count[1],
      core: count[2],
      level: count[index],
      wcp: wcp(count[2], nextLevel, cores)
    }
  })
}

export default withRouter(CoreCounts);
